<template>
	<div class="recommend-container">
		<div class="product-list">
			<ProductCard v-for="(item,index) in proList" @getInfo="getInfo" :key="index" :info="item"
				@handleDelete="handleDelete" @refreshScroll="refreshScroll" />
		</div>
		<!-- <div v-for="(item,i) in proList" :key="i" class="product-list">
      <ProductList :disabled="disabled" :info="item" @handleDelete="handleDelete" @refreshScroll="refreshScroll" />
    </div> -->
	</div>
</template>

<script>
	import ProductList from '@c/common/ProductList.vue'
	import ProductCard from '@c/common/ProductCard.vue'
	export default {
		name: 'RecommendList',
		components: {
			ProductList,
			ProductCard
		},
		props: {
			disabled: {
				default: true
			},
			proList: {
				default: []
			}
		},
		computed: {

		},
		methods: {
			getInfo(data) {
				this.$router.push({
					name: 'ProductDetails',
					query: data
				});
			},
			refreshScroll() {
				this.$emit('refreshScroll')
			},
			handleDelete(info) {
				this.$emit('handleDelete', info)
			}
		}
	}
</script>

<style lang="less" scoped>
	.recommend-container {

		// padding: 7px 0;
		.product-list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin: 10px;
		}
	}
</style>
