<template>
	<div class="container">
		<div class="top-nav-bar">
			<TopNavBar>{{ title }}</TopNavBar>
		</div>
		<!-- <scroll ref="scroll" class="content" :probe-type="3" :pull-up-load="true" @scroll="contentScroll" @pullingUp="loadMore"> -->
		<div v-if="type == 3">
			<div v-for="(item,index) in proList" :key="index">
				<div class="flex-center title-time">
					{{ replaceStr1(item[0].viewTime,4,'年',7,'月')}}日
				</div>
				<div class="flex flex-warp" style="padding:0 5px;">
					<!-- {{it.viewTime}} -->
					<img v-for="(it,i) in item" @click="getInfo(it)" :key="i" class="footprint-img" :src="getImg(it.picList)" />
				</div>
			</div>
		</div>
		<div v-else class="pro-content-list">
			<RecommendList :pro-list="proList" :disabled="disabled" :type="type" @handleDelete="handleDelete" />
		</div>
		<!-- 水印 -->
		<WaterMark />
		<!-- </scroll> -->
	</div>
</template>


<script>
	import {
		getProList,
		getNewProduct,
		getCollList,
		getFootprint,
		reCollection
	} from '@/api'
	// import scroll from '@c/scroll/Scroll.vue'
	import TopNavBar from './components/TopNavBar.vue'
	import RecommendList from './components/RecommendList.vue'

	import emptyImgs from '@/assets/img/stoplist/empty.png';

	export default {
		name: 'ProList',
		components: {
			TopNavBar,
			RecommendList
			// scroll
		},


		data() {
			return {
				title: '镇店之宝',
				type: 0,
				proList: [],
				disabled: false,
				// 0收藏 1喜欢
				likColl: 0,
				emptyImg: emptyImgs
			}
		},

		// activated() {
		//   this.init()
		// },
		mounted() {
			this.init()
		},

		methods: {
			getInfo(data) {
				this.$router.push({
					name: 'ProductDetails',
					query: data
				});
			},
			replaceStr1(str, index, char,index2,char2) {
				const strAry = str.split('');
				strAry[index] = char;
				strAry[index2] = char2;
				return strAry.join('');
			},
			//产品图片
			getImg(file) {
				let img = ''
				let picList = file || [];
				if (picList.length != 0) {
					picList = picList.filter(resp => {
						if (resp.typeName == 'prtPicture') {
							return resp
						}
					})
					img = picList[0]?.filePath
				} else {
					img = this.emptyImg
				}
				return img
			},
			init() {
				const {
					type
				} = this.$route.query
				switch (parseInt(type)) {
					case 0:
						this.title = '镇店之宝'
						this.type = 0
						this.getProList()
						this.disabled = false
						break
					case 1:
						this.title = '我的喜欢'
						this.type = 1
						this.likColl = 1
						this.getCollList()
						this.disabled = false
						break
					case 2:
						this.title = '我的收藏'
						this.type = 2
						this.likColl = 0
						this.getCollList()
						this.disabled = false
						break
					case 3:
						this.title = '浏览记录'
						this.type = 3
						this.getFootprint()
						this.disabled = true
						break
					case 4:
						this.title = '新品上市'
						this.type = 4
						this.getNewProduct()
						this.disabled = true
						break
				}
			},

			// 新品上市
			getNewProduct() {
				const params = {
					isOn: 0,
					nearDate: 2,
					page: 0,
					pageSize: 0,
					shopUuid: this.$store.state.shopUuid
				}
				getNewProduct(params).then(res => {
					if (res.state == 100) {
						this.proList = res.items
						this.loading = true
					}
				})
			},

			// 镇店之宝
			getProList() {
				const params = {
					zhenDian: 0,
					isOn: 0,
					page: 1,
					pageSize: 10,
					shopUuid: this.$store.state.shopUuid
				}
				getProList(params).then(res => {
					if (res.state == 100) {
						this.proList = res.items
					}
				})
			},

			// 0收藏1喜欢
			getCollList() {
				const params = {
					likColl: this.likColl,
					page: 0,
					pageSize: 0,
					shopUuid: this.$store.state.shopUuid
				}
				getCollList(params).then(res => {
					if (res.state == 100) {
						this.proList = res.items
						// this.refreshScroll()
					}
				})
			},

			// 足迹
			getFootprint() {
				const params = {
					page: 0,
					pageSize: 0,
					shopUuid: this.$store.state.shopUuid
				}
				this.proList = []
				getFootprint(params).then(res => {
					if (res.state == 100) {
						if (res.items) {
							res.items.forEach(resp => {
								resp.viewTime = this.splitTime(resp.viewTime)
							});
							this.proList = this.sortClass(res.items)
						}
					}
				})
			},
			sortClass(sortData) {
				const groupBy = (array, f) => {
					let groups = {};
					array.forEach((o) => {
						let group = JSON.stringify(f(o));
						groups[group] = groups[group] || [];
						groups[group].push(o);
					});
					return Object.keys(groups).map((group) => {
						return groups[group];
					});
				};
				const sorted = groupBy(sortData, (item) => {
					return item.viewTime; // 返回需要分组的对象
				});
				return sorted;
			},

			// 删除
			handleDelete(info) {
				const data = {
					likColl: this.likColl,
					prtUuid: info.uuid
				}
				reCollection().then(res => {
					if (res.state == 100) {
						this.init()
					}
				})
			},

			//截取时间
			splitTime(time) {
				let times = time.substring(0, 10)
				return times
			},

			// 监听滚动事件
			contentScroll(position) {
				// 1.判断BackTop是否显示
				// this.isShowBackTop = -position.y > BACKTOP_DISTANCE;
				// 2.觉得tabControl是否吸顶
				// this.isTabFixed = -position.y > this.tabControl;
			},
			// 加载更多
			loadMore() {
				// this.getHomeGoods(this.currentType);
			},
			refreshScroll() {
				this.$nextTick(() => {
					this.$refs.scroll.refresh()
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.container {
		// height: 100vh;
		overflow: auto;
		padding-top: 60px;

		.title-time {
			font-size: 15px;
			font-family: HarmonyOS Sans SC;
			font-weight: 500;
			color: #000000;
			padding: 15px;
		}

		.footprint-img {
			width: 113px;
			height: 113px;
			background: #FFFFFF;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			margin: 4px;
		}

		&::before {
			content: "";
			display: table;
		}

		.top-nav-bar {
			position: fixed;
			top: 0;
			width: 100%;
			height: 60px;
			z-index: 999;
		}

		.pro-type {
			width: 100vw;
			overflow: hidden;
		}

		.pro-content-list {
			width: 100vw;
		}

		.content {
			padding-top: 7px;
			overflow: auto;

			// height: calc(100vh - 170px);
			.pro-content-list {
				padding-bottom: 30px;
			}
		}
	}
</style>
